import logger from '../../config/logger'
import { CMDS } from './constant'
import EventNames, { EventNameType } from './EventNames'
import Convert from './Convert'

import type { UserInfo } from '@/types/uc'
import type { GameRoomInfo } from '../../types/PKGame'

class MessageHandler {
  private dispatch: Dispatch
  private sendGameData: SendGameData
  private onGameRoomChange: OnGameRoomChange
  private getGameRoom: GetGameRoom

  private user!: UserInfo

  constructor(options: {
    dispatch: Dispatch
    sendGameData?: SendGameData
    onGameRoomChange?: OnGameRoomChange
    getGameRoom: GetGameRoom
  }) {
    const { dispatch, sendGameData = () => {}, onGameRoomChange = () => {}, getGameRoom } = options
    this.dispatch = dispatch
    this.sendGameData = sendGameData
    this.onGameRoomChange = onGameRoomChange
    this.getGameRoom = getGameRoom
  }

  public setUser(user: UserInfo) {
    this.user = user
  }

  public handle(data: Message) {
    const { cmd, payload } = data

    if (
      [CMDS.HEART_BIT, CMDS.MATCH_SUCCESS].indexOf(cmd) < 0 &&
      payload &&
      payload.roomNo &&
      payload.roomNo !== this.getGameRoom().roomNo
    ) {
      logger.warn(`ignore: get message ${cmd}, payload:`, payload)
      return
    }

    cmd !== CMDS.HEART_BIT && logger.log(`get message ${cmd}, payload:`, payload)

    switch (cmd) {
      // 心跳
      case CMDS.HEART_BIT:
        this.dispatch(EventNames.HEART_BIT, payload)
        break
      // 匹配成功
      case CMDS.MATCH_SUCCESS:
        this.dispatch(EventNames.MATCH_SUCCESS, this.handleGameRoomData(payload))
        break
      // 进入房间回调
      case CMDS.GAME_ROOM_ENTER:
        this.dispatch(EventNames.GAME_ROOM_ENTER, this.handleGameRoomData(this.handlePreGameRoomData(payload)))
        break
      // 退出房间回调
      case CMDS.GAME_ROOM_QUIT:
        this.dispatch(EventNames.GAME_ROOM_QUIT, this.handleGameRoomData(this.handlePreGameRoomData(payload)))
        break
      // 创建游戏回调
      case CMDS.GAME_BATTLE_CREATE:
        this.dispatch(EventNames.GAME_BATTLE_CREATE, this.handleGameRoomData(payload))
        break
      // 关闭对局回调
      case CMDS.GAME_BATTLE_CLOSE:
        this.dispatch(EventNames.GAME_BATTLE_CLOSE, this.handleGameRoomData(payload))
        break
      // 玩家准备
      case CMDS.GAME_ROOM_USER_READY:
        this.handleUserReady(payload)
        break
      // 玩家取消准备
      case CMDS.GAME_ROOM_USER_CANCEL_READY:
        this.dispatch(EventNames.GAME_ROOM_CANCELREADY, this.handleGameRoomData(payload))
        break
      // 开始倒计时
      case CMDS.GAME_ROOM_COUNTDOWN:
        this.dispatch(EventNames.GAME_ROOM_COUNTDOWN, this.handleGameRoomData(payload))
        break
      // 玩家加注
      case CMDS.GAME_ROOM_BETDOUBLE:
        this.dispatch(EventNames.GAME_ROOM_BETDOUBLE, this.handleGameRoomData(payload))
        break
      // 加载游戏
      case CMDS.GAME_LOAD:
        this.dispatch(EventNames.GAME_LOAD, this.handleGameRoomData(payload, true))
        break
      // 可以开始游戏
      case CMDS.GAME_START:
        this.dispatch(EventNames.GAME_START, this.handleGameRoomData(payload))
        break
      // 游戏结束
      case CMDS.GAME_FINISH:
        this.dispatch(EventNames.GAME_FINISH, this.handleGameRoomData(payload))
        break
      // 退出游戏
      case CMDS.GAME_QUIT:
        this.handleGameRoomData(payload)
        this.dispatch(EventNames.GAME_QUIT, new Date().getTime())
        break
      // 用户离线回调
      case CMDS.GAME_ROOM_USER_OFFLINE:
        this.handleGameRoomData(payload)
        this.dispatch(EventNames.GAME_ROOM_OFFLINE, new Date().getTime())
        break
      // 用户在线回调
      case CMDS.GAME_ROOM_USER_ONLINE:
        this.dispatch(EventNames.GAME_ROOM_ONLINE, this.handleGameRoomData(payload))
        break
      // 转发游戏数据
      case CMDS.GAME_DATA:
        this.handleGameData(payload.data)
        break
      default:
        break
    }
  }

  private handleGameRoomData(gameRoomInfo: PKGameDataNS.GameRoomInfo, addBattleNo: boolean = false): GameRoomInfo {
    const gameRoom = Convert.gameRoomInfoToClient({
      gameRoomInfo: gameRoomInfo,
      user: this.user,
      addBattleNo
    })
    const { battleNo: preBattleNo } = this.getGameRoom()
    if (!addBattleNo && preBattleNo) {
      gameRoom.battleNo = preBattleNo
    }

    this.dispatch(EventNames.GAME_ROOM_CHANGE, gameRoom)
    this.onGameRoomChange(gameRoom)

    return gameRoom
  }

  private handlePreGameRoomData(payload: PKGameDataNS.GameRoomEnterPayload): PKGameDataNS.GameRoomInfo {
    const {
      roomNo,
      userIds,
      userInfos,
      detail: { gameId, gameMode }
    } = payload

    const defaultUser: PKGameDataNS.PKGameUser = {
      header: '',
      name: '',
      id: '',
      txnId: '',
      online: true,
      bet: false,
      bot: false,
      ready: false,
      role: 'default'
    }
    const user1: PKGameDataNS.PKGameUser = userInfos[0]
      ? {
          ...defaultUser,
          ...userInfos[0]
        }
      : defaultUser
    const user2: PKGameDataNS.PKGameUser = userInfos[1]
      ? {
          ...defaultUser,
          ...userInfos[1]
        }
      : defaultUser

    return {
      id: '',
      gameId,
      roomNo,
      gameMode,
      status: -1,
      userIds,
      readyIds: '',
      betIds: '',
      teamsInfo: [
        {
          id: '1',
          score: 0,
          leaderId: '',
          userIds: '',
          userInfos: [user1]
        },
        {
          id: '2',
          score: 0,
          leaderId: '',
          userIds: '',
          userInfos: [user2]
        }
      ]
    }
  }

  private handleUserReady(payload: PKGameDataNS.GameRoomInfo): GameRoomInfo {
    const gameRoom = this.handleGameRoomData(payload)
    if (gameRoom.allReady) {
      this.dispatch(EventNames.GAME_ROOM_ALLREADY, gameRoom)
    }

    return gameRoom
  }

  private handleGameData(data: any): void {
    if (data.__cmd === 'progress') {
      this.dispatch(EventNames.GAME_LOAD_PROGRESS, data.payload)
    } else {
      this.sendGameData(data)
    }
  }
}

type Dispatch = (eventName: EventNameType, ...args: any[]) => void
type SendGameData = (data: any) => void
type OnGameRoomChange = (gameRoom: GameRoomInfo) => void
type GetGameRoom = () => GameRoomInfo

interface Message<T = any> {
  cmd: CMDS
  payload: T
}

export default MessageHandler
