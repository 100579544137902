import { useState, useEffect } from 'react'

function useMultiClick(targetCount: number, resetTime: number, callback: () => void) {
  const [clickCount, setClickCount] = useState(0)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const handleClick = () => {
    setClickCount(prev => prev + 1)

    if (timer) {
      clearTimeout(timer)
    }

    const newTimer = setTimeout(() => {
      setClickCount(0)
    }, resetTime)

    setTimer(newTimer)
  }

  useEffect(() => {
    if (clickCount === targetCount) {
      callback()
      setClickCount(0)
      if (timer) {
        clearTimeout(timer)
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [clickCount, targetCount, callback, timer])

  return handleClick
}

export default useMultiClick
