import { DEFAULT_USER } from './constant'

import type { UserInfo } from '@/types/uc'
import type { GameRoomInfo, GameRoomStatus, PKGameUser } from '../../types/PKGame'

class Convert {
  public static gameRoomInfoToClient(options: {
    gameRoomInfo: PKGameDataNS.GameRoomInfo
    user?: UserInfo
    addBattleNo?: boolean
  }): GameRoomInfo {
    const { gameRoomInfo, user, addBattleNo = false } = options

    const { roomNo, gameId, status, userIds, readyIds, betIds, winner, teamsInfo, startTime } = gameRoomInfo
    let owner = ''

    // 自己排前面
    user && teamsInfo.sort(team => (team.userIds.split(',').includes(user.userId) ? -1 : 1))

    const liveUsers = userIds.split(',')
    const teams = teamsInfo.map(team => {
      const { id, userInfos } = team
      const userOri = userInfos[0]

      let user: PKGameUser = DEFAULT_USER
      // 剔除已下线的玩家
      if (userOri && liveUsers.indexOf(userOri.id) >= 0) {
        const { id: userId, name: nickname, header: avatar, role, bot, botLevel, ...rest } = userOri
        role === 'admin' && (owner = userId)
        user = {
          ...rest,
          userId,
          nickname,
          avatar,
          role,
          bot,
          botLevel: bot ? (botLevel ? botLevel : 4) : undefined
        }
      }

      return {
        id,
        user: [user]
      }
    })

    if (teams.length === 1) {
      teams.push({
        id: '',
        user: [DEFAULT_USER]
      })
    }

    return {
      roomNo,
      battleNo: addBattleNo ? startTime || 0 : undefined,
      gameId,
      owner,
      status: this.gameRoomStatusToClient(status),
      allReady: readyIds.split(',').length === 2,
      allBet: betIds.split(',').length === 2,
      full: liveUsers.length === 2,
      winner,
      teams
    }
  }

  public static gameRoomStatusToClient(status: PKGameDataNS.RoomStatus): GameRoomStatus {
    return status === 0
      ? 'matching'
      : status === 1
      ? 'counting'
      : status === 2
      ? 'loading'
      : status === 3
      ? 'gaming'
      : status === 4 || status === 5
      ? // ? 'processing'
        // : status === 5
        'complete'
      : status === -1
      ? 'none'
      : status
  }
}

export default Convert
