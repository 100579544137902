import { useMutation } from '@tanstack/react-query'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/component/Button'
import TGASDK from '@/config/TGASDK'
import { QUERY_KEYS } from '@/config/constant'
import { queryClient } from '@/config/reactQuery'
import WalletContext from '@/context/WalletContext'
import useLinkTo from '@/hook/useLinkTo'
import { PopUpStore } from '@/store/PopupStore'
import type { TaskItemType } from '@kulee/tga-sdk/types/types/growth'
import { useNavigate } from 'react-router-dom'

const TaskBtn: React.FC<PropType> = ({ task }) => {
  const { t } = useTranslation('growth')
  const { getLink } = useLinkTo()
  const navigate = useNavigate()
  const { init } = useContext(WalletContext)
  const { id, status, link } = task
  const [taskStatus, setTaskStatus] = useState<'undone' | 'done' | 'received'>(status)

  const mutation = useMutation({
    mutationFn: (id: string) => {
      return TGASDK.module.Growth.service.getTaskReward(id)
    }
  })

  const handleLinkClick = () => {
    // TODO 处理任务跳转
    switch (link?.code) {
      case 'PLAY_GAME_COUNT':
      case 'PLAY_GAME_DURATION':
        PopUpStore.setTaskPopupVisible(false)
        if (link?.param?.code) {
          // 跳转到游戏详情
          const newlink = getLink(`/play/${link?.param?.code}/`)
          navigate(newlink)
        } else {
          const newlink = getLink(`/games/`)
          navigate(newlink)
        }
        break
      case 'RECHARGE_COUNT':
      case 'RECHARGE_QUANTITY_SUM':
        PopUpStore.setTopUpGoldPopupVisible(true)
        break
      case 'CHECK_IN_CONTINUE':
      case 'CHECK_IN_SUM':
        PopUpStore.setCheckinPopupVisible(true)
        break
    }
  }

  const handleReceiveClick = async () => {
    mutation.mutate(id, {
      onSuccess: () => {
        setTaskStatus('received')
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.TASKS_STATUS]
        })
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.TASKS]
        })
        init()
      }
    })
  }

  useEffect(() => {
    setTaskStatus(status)
  }, [status])

  if (taskStatus === 'undone' && link) {
    return (
      <Button type="primary" size="small" onClick={handleLinkClick}>
        {t('btn.taskGo')}
      </Button>
    )
  }

  if (taskStatus === 'done' || taskStatus === 'received') {
    return (
      <Button
        type="primary"
        disabled={taskStatus === 'received' || mutation.isSuccess}
        loading={mutation.isPending}
        onClick={handleReceiveClick}
      >
        {taskStatus === 'received' ? t('btn.taskAwarded') : t('btn.taskAward')}
      </Button>
    )
  }

  return null
}

interface PropType {
  task: TaskItemType
}

export default TaskBtn
