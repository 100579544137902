import { CustomError } from '@kulee/helper'

import BaseService from '../../BaseService'
import logger from '../../config/logger'
import { handleError } from '../../helper/request'
import Convert from './Convert'

import type { CustomAxiosResponse, PageParams } from '../../types'
import type {
  BaseGameType,
  BaseTagType,
  GameDetailType,
  GamesWithTag,
  HomeGames,
  TagDetailType
} from '../../types/game'

/**
 * 需要服务端实现的接口
 *
 * 1、游戏
 *  1.1、获取推荐游戏列表
 *  1.1、获取所有游戏列表
 *  1.2、获取最热门游戏列表
 *  1.3、获取最新游戏列表
 *  1.4、获取
 *
 *
 * 2、游戏标签
 */
class GameService extends BaseService {
  /**
   * 获取网站首页游戏列表，服务端根据业务聚合游戏列表
   * @returns 游戏列表
   */
  public async getHomeGames(): Promise<CustomAxiosResponse<HomeGames>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.HomeGames>>(
        `${this.apiUrl}/v1/game/app/game/genre`
      )
      const {
        data: { hots, news, recommends }
      } = res

      return {
        ...res,
        data: {
          recommends: Convert.baseGamesToClient(recommends),
          hots: Convert.baseGamesToClient(hots),
          news: Convert.baseGamesToClient(news)
        }
      }
    } catch (error: any) {
      logger.error('home data init error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 根据游戏编码获取游戏详情
   * @param gameCode 游戏编码
   * @returns 游戏详情
   */
  public async getGameInfo(code: string): Promise<CustomAxiosResponse<GameDetailType>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.GameDetailType>>(
        `${this.apiUrl}/v1/game/app/game/${code}`
      )
      return {
        ...res,
        data: Convert.gameDetailToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get game info error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取所有游戏标签列表
   * @returns 所有游戏标签列表
   */
  public async getAllTags(): Promise<CustomAxiosResponse<BaseTagType[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.BaseTagType[]>>(
        `${this.apiUrl}/v1/game/app/tag/`,
        {
          params: {
            pageSize: 100,
            pageNum: 1
          }
        }
      )
      return {
        ...res,
        data: Convert.baseTagsToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get tags error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取游戏标签详情
   * @param code 游戏标签code
   * @returns 游戏标签详情
   */
  public async getTagInfo(code: string): Promise<CustomAxiosResponse<TagDetailType>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.TagDetailType>>(
        `${this.apiUrl}/v1/game/app/tag/${code}`
      )
      return {
        ...res,
        data: Convert.tagDetailToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get tag info error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 根据游戏标签获取游戏列表
   * @param options 参数
   * @returns 游戏列表
   */
  public async getGamesByTags(
    options: { code: string | string[] } & PageParams
  ): Promise<CustomAxiosResponse<BaseGameType[]>> {
    const { code, ...rest } = options
    const tags = typeof code === 'string' ? code : code.join(',')

    return this.getGames({
      tagCode: tags,
      ...rest
    })
  }

  /**
   * 搜索游戏
   * @param options 参数
   * @returns 游戏列表
   */
  public async searchGames(options: { key: string } & PageParams): Promise<CustomAxiosResponse<BaseGameType[]>> {
    const { key, ...rest } = options

    return this.getGames({
      name: key,
      ...rest
    })
  }

  /**
   * 获取带标签的所有游戏列表
   * @returns 游戏列表
   */
  public async getGamesWithTag(): Promise<CustomAxiosResponse<GamesWithTag[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.GamesWithTag[]>>(
        `${this.apiUrl}/v1/game/app/game/classification`
      )
      return {
        ...res,
        data: Convert.gamesWithTagToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get games with tag error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 智能推荐游戏 根据用户
   * @returns 游戏列表
   */
  public async getRecommendGamesByUser(options: { userId: string }): Promise<CustomAxiosResponse<BaseGameType[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.BaseGameType[]>>(
        `${this.apiUrl}/v1/game/app/game/recommend/user/`,
        {
          params: { ...options }
        }
      )
      return {
        ...res,
        data: Convert.baseGamesToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get recommend games by user error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 智能推荐游戏 根据游戏
   * @returns
   */
  public async getRecommendGamesByGame(options: {
    code: string
    userId: string
  }): Promise<CustomAxiosResponse<BaseGameType[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.BaseGameType[]>>(
        `${this.apiUrl}/v1/game/app/game/recommend/`,
        {
          params: { ...options }
        }
      )
      return {
        ...res,
        data: Convert.baseGamesToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get games error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取游戏列表
   * @param options 参数
   * @returns 游戏列表
   */
  private async getGames(
    options: { name?: string; tagCode?: string } & PageParams = {}
  ): Promise<CustomAxiosResponse<BaseGameType[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.BaseGameType[]>>(
        `${this.apiUrl}/v1/game/app/game/`,
        {
          params: { ...options }
        }
      )
      return {
        ...res,
        data: Convert.baseGamesToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get games error', error)
      throw new CustomError(handleError(error))
    }
  }
}

export default GameService
