import { useEffect, useMemo, useState } from 'react'
import { Wallet } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import { KULEWEBVIEW } from '@/config/constant'
import logger from '@/helper/logger'
import { filterObject } from '@/helper'

import type { WalletContextType, WalletStatus } from '@/type/wallet'
import type { Wallet as WalletType } from '@kulee/tga-sdk/types/types/wallet'

const useProvideWallet = (): WalletContextType => {
  const walletEnabled = window.AppConfig.data.feature.wallet.enabled

  const [status, setStatus] = useState<WalletStatus>('unready')
  const [wallet, setWallet] = useState<WalletType>({
    GOLD: {
      balance: 0,
      freeze: 0,
      allowRecharge: true,
      allowWithdraw: true,
      name: 'gold',
      unit: '',
      prefix: '',
      type: 'coin'
    },
    DIAMOND: {
      balance: 0,
      freeze: 0,
      allowRecharge: true,
      allowWithdraw: true,
      name: 'diamond',
      unit: '',
      prefix: '',
      type: 'token'
    }
  })

  // 初始化钱包
  const init = async () => {
    if (!walletEnabled) {
      return
    }

    try {
      const { data } = await TGASDK.module.Wallet.service.getWallet()

      let wallet = filterObject(data, (v, k) => {
        return window.AppConfig.data.feature.wallet.display.indexOf(k) >= 0
      })

      // bip 国家特殊处理
      // 过滤掉代币
      if (KULEWEBVIEW && (window.AppConfig.data.appCode === 'bip' || window.AppConfig.data.appCode === 'bip-dev')) {
        const { country: code } = await window.Bridge.system.getCountry()
        if (['tr', 'sg'].indexOf(code.toLocaleLowerCase()) < 0) {
          wallet = filterObject(wallet, (v, k) => {
            return v?.type !== 'token'
          })
        }
      }

      setWallet(wallet)
      setStatus('ready')
    } catch (error) {
      logger.error('wallet init error', error)
    }
  }

  useEffect(() => {
    TGASDK.addModule(Wallet)
  }, [])

  return useMemo(
    () => ({
      status,
      wallet,
      init
    }),
    [status, wallet]
  )
}

export default useProvideWallet
