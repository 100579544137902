import { Cookies, browser, URL, CustomError } from '@kulee/helper'
import { STORAGE_KEYS, RESPONSE_CODES } from '@kulee/tga-constant'

import UCService from './Service'
import BaseModule from '../../BaseModule'
import logger from '../../config/logger'
import type {
  LoginOptions,
  LoginWithFpIdOptions,
  RegisterOptions,
  Token,
  UpdateUserInfoOptions,
  UserInfo
} from '../../types/uc'

class UC extends BaseModule {
  public static override clazz = 'UC'

  public service!: UCService

  private userAccessTokenStorageKey!: string
  private userRefreshTokenStorageKey!: string
  private userInfoStorageKey!: string

  public override run(): void {
    logger.log(`run module ${UC.clazz}`)

    this.service = new UCService(this.context)
    this.userAccessTokenStorageKey = STORAGE_KEYS.USER_ACCESS_TOKEN + '-' + this.context.appConfig.appCode
    this.userRefreshTokenStorageKey = STORAGE_KEYS.USER_REFRESH_TOKEN + '-' + this.context.appConfig.appCode
    this.userInfoStorageKey = STORAGE_KEYS.USER_INFO + '-' + this.context.appConfig.appCode
  }

  /**
   * 检测是否登录
   * @returns 用户是否登录
   */
  public checkLogin(): boolean {
    const token = this.getToken()
    const user = this.getUser()

    const isLogin = !!token && !!user
    logger.log('check user login result: ', isLogin)
    return isLogin
  }

  /**
   * 页面初始化时快速登录
   * @returns token
   */
  public async quickLogin(): Promise<Token> {
    logger.log('start to quick login, check kuleWebview: ', browser.version.kuleWebview)

    const { enabled, appLoginEnabled } = this.context.appConfig.feature.uc

    // 禁用用户中心
    if (!enabled) {
      this.logout()

      throw new CustomError({
        code: RESPONSE_CODES.UC_DISABLED,
        message: 'user center disabled.'
      })
    }

    if (appLoginEnabled) {
      if (browser.version.kuleWebview) {
        const userParam = await window.Bridge.system.gameCenterUserParam()
        if (userParam.token && userParam.refreshToken) {
          this.saveToken({
            accessToken: userParam.token,
            refreshToken: userParam.refreshToken
          })

          // 比对本地用户
          const userFromLocal = this.getUser()
          if (
            !userFromLocal ||
            userFromLocal.txnId !== userParam.txnId ||
            userFromLocal.nickname !== userParam.nickName ||
            userFromLocal.avatar !== userParam.avatar
          ) {
            const userFromServer = await this.getUserInfoFromServer()
            // 比对服务端用户
            if (
              userFromServer.txnId !== userParam.txnId ||
              userFromServer.nickname !== userParam.nickName ||
              userFromServer.avatar !== userParam.avatar
            ) {
              try {
                const { token, refreshToken } = await window.Bridge.system.resetToken()
                this.logout()
                this.saveToken({
                  accessToken: token,
                  refreshToken
                })
              } catch (error) {}
            }
          }
        } else {
          this.logout()
        }
      } else {
        const query = URL.getUrlQuery()
        const msisdn = query['msisdn'] || query['txn-id']
        const nickname = query['nickname']
        const avatar = query['avatar']

        if (msisdn) {
          this.logout()
          const { accessToken, refreshToken } = await this.loginWithFpId({
            fpId: msisdn,
            nickname,
            avatar: avatar ? window.decodeURIComponent(avatar) : undefined
          })
          this.saveToken({
            accessToken,
            refreshToken
          })
        }
      }
    } else {
      // app不提供登录，用FpId登录
      this.logout()
      const { accessToken, refreshToken } = await this.loginWithFpId()
      this.saveToken({
        accessToken,
        refreshToken
      })
    }

    // 3、从本地获取
    const token = this.getToken()
    if (token) {
      return token
    }

    throw new CustomError({
      code: RESPONSE_CODES.UC_LOGIN_FAILED,
      message: 'Quick login failed.'
    })
  }

  /**
   * 谨慎使用
   */
  public async generateTempUser(): Promise<UserInfo> {
    const userInfo = this.getUser()

    if (userInfo) {
      return userInfo
    }

    const { accessToken } = await this.loginWithFpId()
    const { data } = await this.service.getUserInfo(accessToken)
    this.saveUser(data)

    return data
  }

  /**
   * 从SDK登录
   * @returns token
   */
  public async loginFromSDK(): Promise<Token> {
    this.logout()

    try {
      const { txnId } = await window.Bridge.uc.login()
      if (txnId) {
        const { token: accessToken, refreshToken } = await window.Bridge.system.gameCenterUserParam()

        this.saveToken({
          accessToken,
          refreshToken
        })
      }
    } catch (error) {
      logger.error('none sdk')
    }

    // 3、从本地获取
    const token = this.getToken()
    if (token) {
      return token
    }

    throw new CustomError({
      code: RESPONSE_CODES.UC_LOGIN_FAILED,
      message: 'Quick login failed.'
    })
  }

  /**
   * 用户名密码登录
   * @param options 登录参数
   * @param options.username 用户名
   * @param options.password 密码
   * @returns token
   */
  public async login(options: LoginOptions): Promise<Token> {
    const res = await this.service.login(options)
    // 请空本地token以及用户信息
    this.removeToken()
    this.removeUser()

    const { accessToken, refreshToken } = res.data

    this.saveToken({
      accessToken,
      refreshToken
    })
    logger.log('login with username and password success')

    return {
      accessToken,
      refreshToken
    }
  }

  /**
   * 从APP端登录
   * @returns 登录token
   */
  // public async loginFromApp(): Promise<Token> {
  //   try {
  //     const token = await this.context.provider.goToLogin()

  //     this.saveToken({
  //       accessToken: token.accessToken,
  //       refreshToken: token.refreshToken
  //     })

  //     logger.log(`login from app success`)

  //     return token
  //   } catch (error: any) {
  //     throw new CustomError({
  //       code: RESPONSE_CODES.UC_LOGIN_FAILED,
  //       message: RESPONSE_CODES.UC_LOGIN_FAILED,
  //       payload: error
  //     })
  //   }
  // }

  /**
   * 注册
   * @param options 注册参数
   * @param options.username 用户名
   * @param options.password 密码
   * @param options.code 验证码
   * @returns token
   */
  public async register(options: RegisterOptions): Promise<Token> {
    const res = await this.service.register(options)
    const { accessToken, refreshToken } = res.data

    this.logout()
    this.saveToken({
      accessToken,
      refreshToken
    })

    logger.log('resister success')

    return {
      accessToken,
      refreshToken
    }
  }

  /**
   * 获取用户信息
   * @returns 用户信息
   */
  public async getUserInfo(): Promise<UserInfo> {
    const userInfo = this.getUser()
    if (userInfo) {
      return userInfo
    }

    return this.getUserInfoFromServer()
  }

  /**
   * 从服务端获取用户信息
   * @returns 用户信息
   */
  public async getUserInfoFromServer(): Promise<UserInfo> {
    const { data } = await this.service.getUserInfo()
    this.saveUser(data)

    logger.log('get user from server success')
    return data
  }

  /**
   * 修改用户信息
   * @param options 修改用户信息参数
   * @param options.userId 要修改的用户ID
   * @param options.nickname 新的用户昵称
   * @param options.avatar 新的用户头像
   * @returns 新的用户信息
   */
  public async updateUserInfo(options: UpdateUserInfoOptions): Promise<UserInfo> {
    logger.log('start to update userInfo')

    await this.service.updateUserInfo(options)
    return this.getUserInfoFromServer()
  }

  /**
   * 更新用户主页封面
   * @param options 封面数据
   * @returns null
   */
  public async updateUserCover(value: string): Promise<UserInfo> {
    logger.log('start to update user page cover')

    await this.service.updateUserExtInfo({
      key: 'cover',
      value: {
        value
      }
    })

    return this.getUserInfoFromServer()
  }

  /**
   * 更新用户签名
   * @param options 用户签名
   * @returns null
   */
  public async updateUserSignature(value: string): Promise<UserInfo> {
    logger.log('start to update user signature')

    await this.service.updateUserExtInfo({
      key: 'signature',
      value: {
        value
      }
    })

    return this.getUserInfoFromServer()
  }

  /**
   * 退出
   */
  public logout(): void {
    this.removeToken()
    this.removeUser()
    logger.log('user logout success')
  }

  /**
   * 获取用户token
   * @returns token或者null
   */
  private getToken(): Token | null {
    const accessToken = Cookies.get(this.userAccessTokenStorageKey)
    const refreshToken = Cookies.get(this.userRefreshTokenStorageKey)

    if (accessToken && refreshToken) {
      return {
        accessToken,
        refreshToken
      }
    }

    return null
  }

  /**
   * 存储用户token
   * @param token 用户登录的token信息
   */
  private saveToken(token: Token): void {
    Cookies.set(this.userAccessTokenStorageKey, token.accessToken)
    Cookies.set(this.userRefreshTokenStorageKey, token.refreshToken)
  }

  /**
   * 移除token
   */
  private removeToken(): void {
    Cookies.remove(this.userAccessTokenStorageKey)
    Cookies.remove(this.userRefreshTokenStorageKey)
  }

  /**
   * 从本地获取用户信息
   * @returns 用户信息
   */
  private getUser(): UserInfo | null {
    try {
      const userInfo = JSON.parse(Cookies.get(this.userInfoStorageKey) || '')

      return userInfo
    } catch (error) {
      return null
    }
  }

  /**
   * 存储用户信息
   * @param userInfo 用户信息
   */
  private saveUser(userInfo: UserInfo): void {
    const userInfoStr = JSON.stringify(userInfo)
    Cookies.set(this.userInfoStorageKey, userInfoStr)
  }

  /**
   * 移除用户信息
   */
  private removeUser(): void {
    Cookies.remove(this.userInfoStorageKey)
  }

  /**
   * 指纹ID登录
   * @param options
   * @param options.fpId fpId
   * @param options.nickname 登录时直接修改的昵称
   * @param options.avatar 登录时直接修改的头像
   * @returns 用户Token
   */
  private async loginWithFpId(options?: LoginWithFpIdOptions): Promise<Token> {
    logger.log('start to login with fpId', options)

    const res = await this.service.loginWithFpId(options)
    const { accessToken, refreshToken } = res.data

    return {
      accessToken,
      refreshToken
    }
  }
}

export default UC
