import React, { useEffect } from 'react'
import { Wallet } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import CurrencyIcon from '@/component/CurrencyIcon'
import { PopUpStore } from '@/store/PopupStore'
import Price from '@/component/Price'
import type { BussnessOrderType, ChargeCommodity } from '@kulee/tga-sdk/types/types/wallet'

const DiamondCommodityItem: React.FC<PropType> = ({ item, onPaySuccess }) => {
  const { id, price, quantity, payCurrencyPrefix, payCurrency } = item

  const handleClick = () => {
    PopUpStore.setPayPopup({
      visible: true,
      commodity: item,
      createOrder: async (paymentCode: string) => {
        const { data } = await TGASDK.module.Wallet.service.createChargeOrder({
          commodityId: id,
          quantity: 1,
          paymentCode
        })

        return data
      },
      onSuccess: onPaySuccess
    })
  }

  useEffect(() => {
    TGASDK.addModule(Wallet)
  }, [])

  return (
    <div
      className="cursor-pointer rounded-xl bg-primary/20 py-md text-center active:bg-primary/40"
      onClick={handleClick}
    >
      <div className="mb-sm flex items-center justify-center gap-[2px]">
        <CurrencyIcon code="diamond" size="small" />
        <span className="text-xl font-custom-bold">{quantity}</span>
      </div>
      <Price currencyCode={payCurrency} currencyPrefix={payCurrencyPrefix} price={price} />
    </div>
  )
}

interface PropType {
  item: ChargeCommodity
  onPaySuccess: (order: BussnessOrderType) => void
}

export default DiamondCommodityItem
