import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'

import { coverUrlToFit, getFileNameFromUrl } from '@/helper'
import { COVER } from '@/config/constant'
import AuthContext from '@/context/AuthContext'
import useMediaBreakpoint from '@/hook/useMediaBreakpoint'
import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '../../Drawer'
import PictureChooseItem from '../component/PictureChooseItem'

const EditCoverPopup: React.FC<PropType> = () => {
  const { t } = useTranslation('uc')
  const { user, updateUserCover } = useContext(AuthContext)
  const { size } = useMediaBreakpoint()
  const editCoverPopupVisible = useStatePopup(PopupSelector.editCoverPopupVisible)
  const [cover, setCover] = useState<string>()

  const handleClose = () => {
    PopUpStore.setEditCoverPopupVisible(false)
  }

  const handleCoverClick = (url: string) => {
    setCover(url)
  }

  const [, cancel] = useDebounce(
    () => {
      cover && updateUserCover(cover)
    },
    500,
    [cover]
  )

  return (
    <Drawer
      visible={editCoverPopupVisible}
      zIndex={901}
      onClose={handleClose}
      lockScroll={true}
      className="bg-primary-light"
      title={t('modalTitle.editCover')}
    >
      <div className="px-md">
        <div className="grid grid-cols-1 gap-sm">
          {COVER.LIST.map((code, index) => (
            <div key={index} className="aspect-[2/1] w-full">
              <PictureChooseItem
                url={coverUrlToFit(`${COVER.BASE_PATH}/${code}.png`, size)}
                checked={getFileNameFromUrl(user?.cover) === code}
                onClick={handleCoverClick}
              />
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  )
}

interface PropType {}

export default EditCoverPopup
