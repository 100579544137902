import React from 'react'
import { useNavigate } from 'react-router-dom'

import useWebTitle from '@/hook/useWebTitle'
import LinkEnhance from '@/component/LinkEnhance'
import { KULEWEBVIEW } from '@/config/constant'
import useMultiClick from '@/hook/useMultiClick'

import { ReactComponent as LogoSVG } from '@/asset/svg/logo.svg'

const Logo: React.FC<PropType> = () => {
  const title = useWebTitle()
  const navigate = useNavigate()

  const handleClick = useMultiClick(5, 1000, () => {
    navigate('/debug/')
  })

  return (
    <h1 className="!text-xl" onClick={handleClick}>
      {true ? (
        <LinkEnhance>{title}</LinkEnhance>
      ) : (
        <LinkEnhance>
          <LogoSVG className="w-40 fill-current" />
        </LinkEnhance>
      )}
    </h1>
  )
}

interface PropType {}

export default Logo
