import React, { useState } from 'react'
import LazyLoad from 'react-lazy-load'

import Skeleton from '../Skeleton'

const Picture: React.FC<PropType> = ({ src, className = '', cover = false, ...rest }) => {
  const [bg, setBg] = useState(true)
  const regex = /(cdn(?:[12]?)\.j4fgame\.com\/(?:games|resource)\/)/

  const handleImgLoad = () => {
    setBg(false)
  }

  return (
    <>
      {src ? (
        regex.test(src) ? (
          <LazyLoad className={`h-full w-full ${bg && 'bg-gray-400/20'} ${className}`}>
            <picture>
              <source type="image/webp" srcSet={src && src.replace(/\.png|\.jpg$/, '.webp')} />
              <img src={src} {...rest} className={`h-full w-full ${cover && 'object-cover'}`} onLoad={handleImgLoad} />
            </picture>
          </LazyLoad>
        ) : (
          <LazyLoad className={`h-full w-full ${bg && 'bg-gray-400/20'} ${className}`}>
            <img src={src} {...rest} className={`h-full w-full ${cover && 'object-cover'}`} onLoad={handleImgLoad} />
          </LazyLoad>
        )
      ) : (
        <Skeleton type="icon" />
      )}
    </>
  )
}

type PropType = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  cover?: boolean
}

export default React.memo(Picture)
