import { CustomError, Cookies } from '@kulee/helper'
import { STORAGE_KEYS } from '@kulee/tga-constant'

import BaseService from '../../BaseService'
import Convert from './Convert'
import logger from '../../config/logger'
import { handleError } from '../../helper/request'

import type { CustomAxiosResponse } from '../../types'
import type { GameRoomInfo, PKGameConfig, PKGameMatchOptions } from '../../types/PKGame'

class PKGameService extends BaseService {
  public getWSUrl(): string {
    const token = Cookies.get(STORAGE_KEYS.USER_ACCESS_TOKEN + '-' + this.appCode)

    return `${this.apiUrl.replace(/^http:\/\/|^https:\/\/|^\/\//, 'wss://')}/ws/user?token=${token}`
  }

  /**
   * 获取对战游戏配置
   * @returns 对战游戏配置信息
   */
  public async getConfig(): Promise<CustomAxiosResponse<PKGameConfig>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<PKGameConfig>>(
        `${this.apiUrl}/v1/battle/app/match/config`
      )

      return res
    } catch (error: any) {
      logger.error('get pkgame config error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 对战游戏开始匹配
   * @param options 进行匹配参数
   * @returns 匹配成功与否
   */
  public async match(options: PKGameMatchOptions): Promise<CustomAxiosResponse<null>> {
    const { gameId, roomNo } = options
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<null>>(
        `${this.apiUrl}/v1/battle/app/match/find`,
        {
          gameId,
          mode: '1v1',
          roomNo
        }
      )

      return {
        ...res,
        data: null
      }
    } catch (error: any) {
      logger.error('pkgame match error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 退出匹配
   * @returns
   */
  public async quitMatch(): Promise<CustomAxiosResponse<null>> {
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<null>>(
        `${this.apiUrl}/v1/battle/app/match/find/quit`
      )

      return {
        ...res,
        data: null
      }
    } catch (error: any) {
      logger.error('pkgame quit match error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 退出游戏
   * @returns
   */
  public async quitGame(): Promise<CustomAxiosResponse<null>> {
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<null>>(`${this.apiUrl}/v1/battle/app/game/quit`)

      return {
        ...res,
        data: null
      }
    } catch (error: any) {
      logger.error('pkgame quit game error', error)
      throw new CustomError(handleError(error))
    }
  }

  public async quitGameRoom(roomNo: string): Promise<CustomAxiosResponse<null>> {
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<null>>(
        `${this.apiUrl}/v1/battle/app/room/quit`,
        {
          roomNo
        }
      )

      return {
        ...res,
        data: null
      }
    } catch (error: any) {
      logger.error('pkgame quit room error', error)
      throw new CustomError(handleError(error))
    }
  }

  public async getGameRoomInfo(): Promise<CustomAxiosResponse<GameRoomInfo>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<PKGameDataNS.GameRoomInfo>>(
        `${this.apiUrl}/v1/battle/app/game/current`
      )

      return {
        ...res,
        data: Convert.gameRoomInfoToClient({
          gameRoomInfo: res.data
        })
      }
    } catch (error: any) {
      logger.error('get game room info error', error)
      throw new CustomError(handleError(error))
    }
  }

  public async createGameRoom(roomNo: string): Promise<CustomAxiosResponse<null>> {
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<string>>(
        `${this.apiUrl}/v1/battle/app/room/invite`,
        {
          roomNo
        }
      )

      return {
        ...res,
        data: null
      }
    } catch (error: any) {
      logger.error('create game room error', error)
      throw new CustomError(handleError(error))
    }
  }

  public async enterGameRoom(options: PKGameMatchOptions): Promise<CustomAxiosResponse<null>> {
    const { gameId, roomNo } = options
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<string>>(
        `${this.apiUrl}/v1/battle/app/room/enter`,
        {
          gameId,
          mode: '1v1',
          roomNo
        }
      )

      return {
        ...res,
        data: null
      }
    } catch (error: any) {
      logger.error('enter game room error', error)
      throw new CustomError(handleError(error))
    }
  }
}

export default PKGameService
